import { render, staticRenderFns } from "./appraiseForUser.vue?vue&type=template&id=c854f062&scoped=true"
import script from "./appraiseForUser.vue?vue&type=script&lang=js"
export * from "./appraiseForUser.vue?vue&type=script&lang=js"
import style0 from "./appraiseForUser.vue?vue&type=style&index=0&id=c854f062&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c854f062",
  null
  
)

export default component.exports